import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

function NotFoundPage() {
  return (
    <>
      <Seo title="Nicht gefunden" />
      <Layout>
        <div className="wrapper" style={{ marginTop: '100px' }}>
          <h1>Seite nicht gefunden</h1>
        </div>
      </Layout>
    </>
  );
}

export default NotFoundPage;
